import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/common/forms/contact"
import StaticHero from "../components/common/staticHero/staticHero"
import "../components/common/heroBanner/heroBanner.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"

const Contact = ({ pageContext }) => {
  const intl = useIntl()

  const seoData = setSeoData({
    lang: intl.locale,
    title: intl.formatMessage({ id: "page.contact.seo.title" }),
    field_seo: {
      description: intl.formatMessage({
        id: "page.contact.seo.meta.description",
      }),
      title: intl.formatMessage({ id: "page.contact.seo.title" }),
      og_description: intl.formatMessage({
        id: "page.contact.seo.ogTags.description",
      }),
      og_image: intl.formatMessage({ id: "page.contact.seo.ogTags.image" }),
      og_locale: intl.locale,
      og_title: intl.formatMessage({ id: "page.contact.seo.ogTags.title" }),
      og_type: intl.formatMessage({ id: "page.contact.seo.ogTags.type" }),
    },
  })

  return (
    <Layout pageContext={pageContext}>
      <StaticHero />
      <Seo data={seoData} />
      <ContactForm />
    </Layout>
  )
}

export default Contact

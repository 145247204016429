import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import {
  getAccessToken,
  getSessionToken,
  pattern,
  RedirectThankYou,
} from "./formUtils"
import "./forms.scss"
import { useIntl } from "gatsby-plugin-react-intl"
import axios from "axios"
import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"
import { Link, navigate } from "gatsby"
import { getWebformURL } from "../functions"

const Contact = () => {
  const intl = useIntl()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm()
  const [formResponse, setFormResponse] = useState("")
  const { isSubmitSuccessful, isSubmitting } = formState
  const [submitted, setSubmitted] = useState(false)
  const submit = token => { }

  const [formClass, setFormClass] = useState("")

  let classHide = !isSubmitting && isSubmitSuccessful ? "input-hide" : ""

  if (formClass != "") {
    classHide = formClass
  }

  const [fullName, setFullName] = useState("")
  const submitHandlerContact = async (values, e) => {
    const sessionToken = await getSessionToken()
      .then(data => {
        return data
      })
      .catch(err => console.log(err))

    if (values.full_name) {
      setFullName(values.full_name)
    }

    const accessToken = await getAccessToken()
      .then(data => {
        return data
      })
      .catch(err => console.log(err))

    const options = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-CSRF-Token": sessionToken,
    }

    // Handle required radio buttons - post empty value to API if "No" option is selected
    if (values.opt_in === "No") {
      values.opt_in = ""
    }

    const body = {
      webform_id: "contact",
      values: values,
    }

    axios({
      method: "post",
      url: getWebformURL(intl.locale),
      data: JSON.stringify(body),
      headers: options,
    })
      .then(data => {
        e.target.reset()
      })
      .catch(error => {
        setFormResponse("Error submitting form")
      })
  }
  return (
    <section className="blk-form pb-50 pt-50">
      <Container>
        <form onSubmit={handleSubmit(submitHandlerContact)}>
          <div className={classHide}>
            <Row>
              <Col className="contact-details">
                <FormControl required error={errors.full_name}>
                  <InputLabel htmlFor="full_name">
                    {intl.formatMessage({ id: "form_inputs.full_name" })}
                  </InputLabel>
                  <Input
                    id="full_name"
                    {...register("full_name", {
                      required: true,
                      pattern: pattern.name,
                    })}
                  />
                  {errors.full_name && errors.full_name.type === "pattern" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.name_pattern",
                      })}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl required error={errors.email}>
                  <InputLabel htmlFor="email">
                    {intl.formatMessage({ id: "form_inputs.email" })}
                  </InputLabel>
                  <Input
                    id="email"
                    {...register("email", {
                      required: true,
                      pattern: pattern.email,
                    })}
                  />
                  {errors.email && errors.email.type === "pattern" && (
                    <FormHelperText>
                      {intl.formatMessage({
                        id: "form_validation.email_pattern",
                      })}
                    </FormHelperText>
                  )}
                  {errors.email && errors.email.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col className="contact-message">
                <FormControl required error={errors.message} fullWidth>
                  <InputLabel htmlFor="message">
                    {intl.formatMessage({ id: "form_inputs.message" })}
                  </InputLabel>
                  <Input
                    id="message"
                    {...register("message", { required: true })}
                    multiline
                  />
                  {errors.message && errors.message.type === "required" && (
                    <FormHelperText>
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                </FormControl>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl
                  required
                  error={errors.opt_in}
                  className="radio-form"
                >
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="opt_in"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "form_inputs.opt_in_yes",
                          })}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label={intl.formatMessage({
                            id: "form_inputs.opt_in_no",
                          })}
                        />
                      </RadioGroup>
                    )}
                  />
                  {errors.opt_in && errors.opt_in.type === "required" && (
                    <FormHelperText className="helper-required">
                      {intl.formatMessage({ id: "form_validation.required" })}
                    </FormHelperText>
                  )}
                  <FormHelperText className="form-helper-policy">
                    {intl.formatMessage({ id: "form_inputs.opt_in_helper" })}{" "}
                    <Link
                      to={intl.formatMessage({ id: "privacy_policy.link" })}
                    >
                      {intl.formatMessage({ id: "privacy_policy.title" })}
                    </Link>
                  </FormHelperText>
                </FormControl>
              </Col>
            </Row>
            <Row className="contact-submit">
              <ReCaptcha
                action="homepage"
                siteKey="6Lfbp40dAAAAAJOpveRAqXiGrdtMyEwueI-1nrCz"
                onVerify={token => submit(token)}
                submitted={submitted}
              />
              <button
                className="btn"
                onClick={() => setSubmitted(true)}
                type="submit"
              >
                <span>{intl.formatMessage({ id: "form_inputs.send" })}</span>
              </button>
            </Row>
          </div>
          {formResponse && (
            <Row className="success-elem">
              <Col>
                <div className="form-message" style={{ color: "red" }}>
                  {formResponse}
                </div>
              </Col>
            </Row>
          )}
          {!isSubmitting && isSubmitSuccessful && !formResponse && (
            intl.locale === "en" ? (
              navigate(`/thank-you?titre=pagecontact?&name=${fullName}`)
            ) : (
              navigate(`/fr/merci?titre=pagecontact?&name=${fullName}`)
            )
          )}
        </form>
      </Container>
    </section>
  )
}

export default Contact

import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import Heading from "../heading"
//import Background from "./contact-bel-ombre-territory.jpg"
import Banner from "./contact-bel-ombre-territory.webp"
import { useIntl } from "gatsby-plugin-react-intl"

const StaticHero = () => {
  const intl = useIntl()

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1000,
    lazyLoad: true,
    autoplaySpeed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <section className="hero custom-dots height-small">
      <Slider {...settings}>
        <div>
          <div className="hero-element">
            <img
              className="wideBanner"
              src={Banner}
              width="1920"
              height="600"
              alt="contact"
            />
            <Container className="hero-contents">
              <Row className="justify-content-center alignCenter">
                <Col md={10}>
                  <Heading
                    caption={intl.formatMessage({
                      id: "contact.banner_caption",
                    })}
                    title={intl.formatMessage({ id: "contact.banner_title" })}
                    fontStyle={1}
                    titleHeadingSize={2}
                    description={intl.formatMessage({
                      id: "contact.description",
                    })}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Slider>
    </section>
  )
}

export default StaticHero
